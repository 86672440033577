// import Vue from 'vue'
import { createRouter, createWebHistory } from "vue-router";
// import HelloWorld from '@/components/HelloWorld'
import HomeLandPage from '@/views/HomeLandPage.vue'
import LoginView from '@/views/LoginView.vue'

import ProtostarsEY from '@/views/ProtostarsEY.vue'

import EarlyInvite from "@/views/EarlyInvite.vue";
import RequestCallBack from "@/views/RequestCallBack.vue";

import BlogListView from '@/views/blogs/ListView.vue'
import BlogDetailView from '@/views/blogs/PostView.vue'

import { i18n } from '../main'
import axios from "axios";

// Vue.use(Router)

const routes = [
  {
    path: '/',
    name: 'HomeLandPage',
    component: HomeLandPage
  },
  {
    path: '/login',
    name: 'LoginView',
    component: LoginView
  },
  {
    path:'/protostars_ey',
    name:'ProtostarsEY',
    component:ProtostarsEY
  },
  {
    path:'/early-invite',
    name:'EarlyInvite',
    component:EarlyInvite
  },
  {
    // path:'/request-call-back',
    path:'/cyber-grants',
    name:'RequestCallBack',
    component:RequestCallBack
  },
  {
    path:'/blog',
    name:'BlogListView',
    component:BlogListView
  },
  {
    // path:'/request-call-back',
    path:'/blog/:id',
    name:'BlogDetailView',
    component:BlogDetailView
  },
]

const router = createRouter({
  history: createWebHistory(),
  mode: 'history',
  routes,
  scrollBehavior: function (to) {
    console.log(to)
    if (to.hash) {
      return {
        selector: to.hash
      }
    }
  },
});
// = new Router({
//   mode: 'history',
//   routes,
//   scrollBehavior: function (to) {
//     if (to.hash) {
//       return {
//         selector: to.hash
//       }
//     }
//   },
// })

router.beforeEach(async (to, from, next) => {
  console.log("in router-beforeEach", i18n)
  console.log(i18n.global.locale)
  console.log(localStorage.getItem('language'))
  console.log("navigator.language", navigator.language)
  console.log(navigator)
  console.log(window.navigator.language, navigator.userLanguage)
  // console.log("in router-beforeEach", $i18n)

  let nav_lang = 'en'
  if(navigator.language=='fr' || navigator.language=='fr-FR'){
    nav_lang='fr'
  }else{
    nav_lang='en'
  }
  console.log("nav_lang",nav_lang)

  await axios
    .get("https://ipinfo.io/json", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then(async (response) => {
      console.log("response", response);

      if (response.data.country == 'FR') {
        i18n.global.locale = localStorage.getItem('language') || 'fr'
      } else {
        i18n.global.locale = localStorage.getItem('language') || 'en'

      }

    })
    .catch((error) => {
      console.log("Error", error)
      i18n.global.locale = localStorage.getItem('language') || nav_lang 



    });
  console.log("in router-beforeEachsss")
  // i18n.global.locale = localStorage.getItem('language') || navigator.language || 'en'
  return next()

})

export default router
