<template>
  <div
    className="flex flex-col min-h-screen overflow-hidden supports-[overflow:clip]:overflow-clip"
  >
    <!-- Site header -->
    <HeaderSection />

    <!-- Page content -->
    <main class="grow mb-8">
      <section>
        <div class="relative max-w-6xl mx-auto px-4 sm:px-6">
          <!-- Particles animation -->
          <ParticlesComponent class="absolute inset-0 -z-10" />

          <!-- Illustration -->
          <div
            class="absolute inset-0 -z-10 -mx-28 rounded-3xl pointer-events-none overflow-hidden"
            aria-hidden="true"
          >
            <div class="absolute right-[40%] -translate-x-1/2 top-0 -z-10">
              <img
                src="@/assets/images/stellar/glow-top-bottom.svg"
                class="max-w-none w-[360px] h-[360px]"
                alt="Hero Illustration"
              />
            </div>
          </div>

          <div class="pt-10 pb-10 md:pt-20 md:pb-2">
            <div
              class="container mx-auto min-h-52 lg:flex lg:flex-col block lg:py-12 py-3 md:px-4 px-px"
            >
              <!-- Page header -->
              <div class="text-center md:mb-6 mb-3 max-md:mt-4">
                <h1
                  class="font-inter xl:text-[50px]/tight lg:text-5xl/tight md:text-3xl/tight text-xl/tight font-bold capitalize from-slate-50/60 via-slate-50 to-slate-50/60 mb-4 bg-clip-text text-transparent bg-gradient-to-r"
                  v-html="$t('blog_title')"
                ></h1>
              </div>

              <!-- blog list -->
              <main
                class="my-8 mx-auto md:mx-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8"
              >
                <div
                  class="flex mx-auto"
                  v-for="(blog, index) in blog_list"
                  :key="index"
                >
                  <router-link
                    :to="{ name: 'BlogDetailView', params: { id: blog.id } }"
                  >
                    <div class="max-w-sm overflow-hidden">
                      <img
                        class="w-full h-48 object-cover rounded"
                        :src="blog.img"
                        :alt="blog.title"
                      />
                      <div class="mt-5">
                        <h2
                          class="font-inter lg:text-xl/normal md:text-lg/normal text-base/normal font-bold text-white mb-2"
                        >
                          {{
                            $i18n.locale == "fr" ? blog.title_fr  :blog.title_en 
                          }}
                        </h2>
                        <p
                          class="font-inter md:text-base/normal text-sm font-normal text-grey-100"
                        >
                          {{
                            $i18n.locale == "fr" ? blog.text_fr : blog.text_en
                          }}
                        </p>
                      </div>
                    </div>
                  </router-link>
                </div>
              </main>

              <!--pagination-->
              <div
                class="mt-8 mb-2 w-full flex flex-wrap h-full mx-0 justify-between px-4 pb-4"
              >
                <div class="md:w-1/2 w-full flex items-center md:mt-2 mt-5">
                  <p class="dataset-info max-md:text-center max-md:mx-auto">
                    {{ $t("table_show_entries_showing") }} {{ from_page }}
                    {{ $t("table_show_entries_to") }} {{ to_page }}
                    {{ $t("table_show_entries_of") }} {{ total_items }}
                    {{ $t("table_show_entries_entries") }}
                  </p>
                </div>
                <div class="md:w-1/2 w-full flex md:mt-2 mt-5">
                  <div
                    class="w-auto max-w-full inline-flex items-center md:float-right md:mr-0 mx-auto"
                  >
                    <vue-awesome-paginate
                      class="paginate-container float-right"
                      :total-items="blog_list.length"
                      :items-per-page="per_page"
                      :max-pages-shown="3"
                      :show-ending-buttons="true"
                      v-model="currentPage"
                      :on-click="onClickHandler"
                    >
                      <template #prev-button>
                        <font-awesome-icon icon="fa-solid fa-angle-left" />
                      </template>
                      <template #next-button>
                        <font-awesome-icon icon="fa-solid fa-angle-right" />
                      </template>
                      <template #first-page-button>
                        <font-awesome-icon icon="fa-solid fa-angles-left" />
                      </template>
                      <template #last-page-button>
                        <font-awesome-icon icon="fa-solid fa-angles-right" />
                      </template>
                    </vue-awesome-paginate>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <SubscribeNewsletter />
    </main>
    <FooterSection />
  </div>
</template>

<script>
import HeaderSection from "@/partials/HeaderSection.vue";
import FooterSection from "@/partials/FooterSection.vue";
import ParticlesComponent from "@/partials/ParticlesComponent.vue";
import SubscribeNewsletter from "@/partials/SubscribeNewsletter.vue";

import axios from "axios";

export default {
  name: "BlogListView",
  components: {
    HeaderSection,
    FooterSection,
    ParticlesComponent,
    SubscribeNewsletter,
  },
  data() {
    return {
      is_loading: false,

      blog_list: [
        {
          id: 1,
          img: "https://positivethinking.tech/wp-content/uploads/2021/01/Logo-Vuejs.png",
          title_en: "Making component design decisions in React",
          text_en:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.",
          title_fr: "fr Making component design decisions in React",
          text_fr:
            "fr Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.",
        },
        {
          id: 2,
          img: "https://tailwindcss.com/img/card-top.jpg",
          title_en: "How to use structured content for page building",
          text_en:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.",
          title_fr: "fr How to use structured content for page building",
          text_fr:
            "fr Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.",
        },
        {
          id: 3,
          img: "https://s3.amazonaws.com/coursesity-blog/2020/04/2.png",
          title_en: "Deploy on Netlify, Heroku or your favorite platform",
          text_en:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.",
          title_fr: "fr Deploy on Netlify, Heroku or your favorite platform",
          text_fr:
            "fr Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.",
        },
        {
          id: 4,
          img: "https://positivethinking.tech/wp-content/uploads/2021/01/Logo-Vuejs.png",
          title_en: "Making component design decisions in React",
          text_en:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.",
          title_fr: "fr Making component design decisions in React",
          text_fr:
            "fr Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.",
        },
        {
          id: 5,
          img: "https://positivethinking.tech/wp-content/uploads/2021/01/Logo-Vuejs.png",
          title_en: "How to use structured content for page building",
          text_en:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.",
          title_fr: "fr How to use structured content for page building",
          text_fr:
            "fr Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.",
        },
        {
          id: 6,
          img: "https://tailwindcss.com/img/card-top.jpg",
          title_en: "Deploy on Netlify, Heroku or your favorite platform",
          text_en:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.",
          title_fr: "fr Deploy on Netlify, Heroku or your favorite platform",
          text_fr:
            "fr Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.",
        },
        {
          id: 7,
          img: "https://positivethinking.tech/wp-content/uploads/2021/01/Logo-Vuejs.png",
          title_en: "Making component design decisions in React",
          text_en:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.",
          title_fr: "fr Making component design decisions in React",
          text_fr:
            "fr Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.",
        },
        {
          id:8,
          img: "https://positivethinking.tech/wp-content/uploads/2021/01/Logo-Vuejs.png",
          title_en: "How to use structured content for page building",
          text_en:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.",
          title_fr: "fr How to use structured content for page building",
          text_fr:
            "fr Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.",
        },
        {
          id:9,
          img: "https://positivethinking.tech/wp-content/uploads/2021/01/Logo-Vuejs.png",
          title_en: "Deploy on Netlify, Heroku or your favorite platform",
          text_en:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.",
          title_fr: "fr Deploy on Netlify, Heroku or your favorite platform",
          text_fr:
            "fr Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.",
        },
        {
          id:10,
          img: "https://positivethinking.tech/wp-content/uploads/2021/01/Logo-Vuejs.png",
          title_en: "Making component design decisions in React",
          text_en:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.",
          title_fr: "fr Making component design decisions in React",
          text_fr:
            "fr Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.",
        },
        {
          id:11,
          img: "https://positivethinking.tech/wp-content/uploads/2021/01/Logo-Vuejs.png",
          title_en: "How to use structured content for page building",
          text_en:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.",
          title_fr: "fr How to use structured content for page building",
          text_fr:
            "fr Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.",
        },
        {
          id:12,
          img: "https://positivethinking.tech/wp-content/uploads/2021/01/Logo-Vuejs.png",
          title_en: "Deploy on Netlify, Heroku or your favorite platform",
          text_en:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.",
          title_fr: "fr Deploy on Netlify, Heroku or your favorite platform",
          text_fr:
            "fr Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.",
        },
      ],

      per_page: 12,
      currentPage: 1,
      total_items: 12,
      from_page: 1,
      to_page: 12,
    };
  },
  mounted() {},
  methods: {
    onClickHandler() {
      console.log("click page", this.currentPage);
    },
  },
};
</script>

<style scoped></style>
